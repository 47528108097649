import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

const PageTransition = ({ children, location }) => {
    const handleAnimationComplete = () => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Otherwise, scroll to the top of the page
            window.scrollTo(0, 0);
        }
    };

    return (<AnimatePresence initial={false} mode="wait">
        <motion.div key={location.pathname} initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{
                        ease: "easeInOut",
                        duration: .5
                    }}
                    onAnimationComplete={handleAnimationComplete}>
            {children}
        </motion.div>
    </AnimatePresence>);
};

export default PageTransition;
