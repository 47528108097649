import React, {useRef, useState} from "react";
import Icon from "../helper/Icon";
import {Link} from "gatsby";
import styled from "styled-components";
import {navigate} from 'gatsby';
import DatenschutzAcceptance from "./DatenschutzAcceptance";
import NavButton from "../elements/NavButton";
import Marquee from "react-fast-marquee";

const Menu = styled.div`
    display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const Navigation = ({settings}) => {

    const [nav, changeNavState] = useState(false);
    const [navBtn, changeNavBtnState] = useState(false);

    const menuRef = useRef();

    const changeNav = () => {
        if (nav) {
            document.body.classList.remove("stop-scrolling");
            menuRef.current.classList.add('fade-out');
            changeNavBtnState(false);

            setTimeout(() => {
                changeNavState(false);
                if (menuRef.current) {
                    menuRef.current.classList.remove('fade-out');
                }
            }, 300)
        } else {
            document.body.classList.add("stop-scrolling");
            changeNavState(true);
            changeNavBtnState(true);
        }
    }
    return (
        <div>
            <nav className={"hidden tablet:block fixed top-0 left-0 w-nav p-page h-screen z-10"}>
                <Link to={"/"} className={`logo`}>
                    <svg className={"navlogo w-full tall:hidden h-auto"} xmlns="http://www.w3.org/2000/svg" width="149" height="526.368" viewBox="0 0 149 526.368">
                        <g id="Group_186" data-name="Group 186" transform="translate(0 0.001)">
                            <g id="Group_88" data-name="Group 88" transform="translate(4.188 373.005)">
                                <path id="Path_11" data-name="Path 11" d="M.167,0Q16.96,7.348,40.223,21.521q19.59,12.071,39.006,23.968,22.744,12.944,38.657,17.844a14.93,14.93,0,0,0,2.8.349,13.281,13.281,0,0,0,9.968-4.711,32.806,32.806,0,0,0,6.124-10.12V35.243q-10.466,9.161-13.957,11.4-9.247,6.219-18.145,6.219a43.48,43.48,0,0,1-4.623-.175,22.918,22.918,0,0,1-4.45-1.222A25.263,25.263,0,0,1,82.778,40.652a33.2,33.2,0,0,1-4.45-17.272,50.518,50.518,0,0,1,1.4-10.644A41.023,41.023,0,0,1,81.3,6.979l57.75.873-.176,145.51q0-.348-20.063-16.052-24.6-19.366-45.365-34.022Q43.963,82.351,25.464,72.929a16.832,16.832,0,0,0-5.582-1.046A16.241,16.241,0,0,0,9.063,76.07a22.024,22.024,0,0,0-6.8,10.295A23.458,23.458,0,0,0,1.737,90.9a12.959,12.959,0,0,0,1.307,5.321q1.312,2.881,2.182,4.974a22.971,22.971,0,0,1,5.932-10.119A38,38,0,0,1,21.625,84.62q1.747-.172,3.055-.263t2.182-.087A35.458,35.458,0,0,1,38.636,86.19,32.315,32.315,0,0,1,49.019,92.3a27.735,27.735,0,0,1,8.374,13.26,63.733,63.733,0,0,1,2.617,19.018v7.152c-.351,1.979-.581,3.433-.7,4.362a58.223,58.223,0,0,1-6.628.263q-6.1.09-10.642.087-9.945,0-20.415-.087T.167,136.088q0-19.366-.175-46.409T-.357,39.257q0-13.085.087-24.6T.167,0" transform="translate(0.357)"/>
                            </g>
                            <g id="Group_89" data-name="Group 89" transform="translate(0 234.009)">
                                <path id="Path_12" data-name="Path 12" d="M34.386,2.278a80.927,80.927,0,0,1,30.1,29.486,78.929,78.929,0,0,1,10.9,40.827v8.025q0,24.6-15.912,45.363T21.35,152.5a70.052,70.052,0,0,1-19.762,2.617,83.561,83.561,0,0,1-29.467-5.321,56.853,56.853,0,0,1-23.7-16.314,73.814,73.814,0,0,1-16.351-26.694,95.607,95.607,0,0,1-5.685-32.8A98.969,98.969,0,0,1-68.029,40.75a76.694,76.694,0,0,1,16.4-27.306A61.706,61.706,0,0,1-29.036-.165,82.5,82.5,0,0,1-1.38-4.876,90.928,90.928,0,0,1,34.386,2.278M11.879,67.356a12.53,12.53,0,0,0-9.247-3.663A12.91,12.91,0,0,0-7.836,68.578,14.386,14.386,0,0,0-10.978,78q0,6.8,9.42,12.387-1.741-.348-13.782-.349-15.873,0-28.265.175t-22.331.349a31.588,31.588,0,0,0,7.154,11.688,67.321,67.321,0,0,0,9.071,6.8A71.4,71.4,0,0,0-31.389,111.5a56.627,56.627,0,0,0,22.332-4.362A44.862,44.862,0,0,0,8.39,94.05a57.809,57.809,0,0,0,5.233-8.025,19.132,19.132,0,0,0,2.617-8.9,13.52,13.52,0,0,0-4.362-9.771" transform="translate(73.613 4.876)"/>
                            </g>
                            <g id="Group_90" data-name="Group 90" transform="translate(3.839 156.004)">
                                <path id="Path_13" data-name="Path 13" d="M33.052,0A42.712,42.712,0,0,1,51.023,5.932,37.176,37.176,0,0,1,64.456,19.366,97.023,97.023,0,0,1,67.511,35.5a168.068,168.068,0,0,1,.959,18.233q0,6.633-.522,19.715T67.423,92.82H7.228a57.841,57.841,0,0,1-2.965-9.771A52.287,52.287,0,0,1,3.043,71.883,42.129,42.129,0,0,1,5.485,57.226,25.7,25.7,0,0,1,13.86,45.885a46.1,46.1,0,0,1,10.207-6.194,26.691,26.691,0,0,1,10.381-2.006A30.684,30.684,0,0,1,46.4,40.39a24.594,24.594,0,0,1,10.9,9.684q1.571,2.445,6.282,12.212V56.529a51.066,51.066,0,0,0-2.79-17.8q-3.666-9.771-11.515-13.609a29.489,29.489,0,0,0-5.062-.7q-8.721,0-19.364,9.247-4.885,4.54-9.946,8.9A73.952,73.952,0,0,1-14.055,56.18q-12.04,2.793-30.184,2.792-6.976,0-13.522-.175t-13-.349V0H33.052Z" transform="translate(70.759)"/>
                            </g>
                            <g id="Group_91" data-name="Group 91" transform="translate(3.795 -0.001)">
                                <path id="Path_14" data-name="Path 14" d="M44.454,0l-.175,68.916L.313,86.188,44.279,88.98q0,6.281.175,9.77.871,17.449.873,18.494a90.945,90.945,0,0,1-1.92,20.588q-2.441,10.469-8.55,17.011a45.821,45.821,0,0,1-14.4,10.381,38.987,38.987,0,0,1-16.493,3.838h-2.7a16.808,16.808,0,0,1-3.751-.7,154.9,154.9,0,0,1-32.461-11.688,341.842,341.842,0,0,1-30.893-17.1q-14.654-9.421-29.319-18.842V50.945q20.244-9.247,45.2-18.32Q-33.731,26.7-5.114,17.1,25.957,6.629,40.441,1.221ZM-12.772,89.679q-11.342,4.186-22.682,8.2-13.611,4.885-32.1,12.039-16.921,6.457-23.029,9.42l11.688,4.014a51.241,51.241,0,0,0,7.852,1.831,59.154,59.154,0,0,0,8.9.61,72.59,72.59,0,0,0,14.219-1.307,72.354,72.354,0,0,0,13-3.926,65.586,65.586,0,0,0,14.48-11.515Q-12.6,100.5-12.6,92.121a13.206,13.206,0,0,0-.175-2.443" transform="translate(95.167)"/>
                            </g>
                        </g>
                    </svg>
                    <svg className={"navlogoalt hidden w-full h-auto"} xmlns="http://www.w3.org/2000/svg" width="347.264" height="98.31" viewBox="0 0 347.264 98.31">
                        <g id="Group_191" data-name="Group 191" transform="translate(0 0)">
                            <path id="Path_19" data-name="Path 19" d="M101.176,3.11a229.023,229.023,0,0,1-14.2,26.427Q79.014,42.461,71.165,55.271q-8.54,15-11.773,25.5a9.812,9.812,0,0,0-.23,1.847,8.76,8.76,0,0,0,3.108,6.576,21.642,21.642,0,0,0,6.677,4.04h8.978A105.378,105.378,0,0,1,70.4,84.026a21.437,21.437,0,0,1-4.1-11.971A28.752,28.752,0,0,1,66.416,69a15.094,15.094,0,0,1,.806-2.936,16.664,16.664,0,0,1,7.136-8.461,21.9,21.9,0,0,1,11.4-2.936,33.345,33.345,0,0,1,7.018.927,27.157,27.157,0,0,1,3.8,1.036L96,94.735,0,94.619q.229,0,10.59-13.236,12.772-16.232,22.443-29.93Q46.847,32,53.062,19.8a11.108,11.108,0,0,0,.69-3.678A10.715,10.715,0,0,0,50.99,8.984,14.534,14.534,0,0,0,44.2,4.491a15.525,15.525,0,0,0-2.992-.346,8.558,8.558,0,0,0-3.511.862q-1.9.866-3.281,1.439a15.16,15.16,0,0,1,6.676,3.913,25.062,25.062,0,0,1,4.259,6.906q.113,1.154.173,2.015t.054,1.441A23.384,23.384,0,0,1,44.31,28.49a21.329,21.329,0,0,1-4.028,6.85,18.3,18.3,0,0,1-8.748,5.525,42.068,42.068,0,0,1-12.545,1.726H14.27c-1.306-.231-2.265-.383-2.878-.462a38.457,38.457,0,0,1-.173-4.373q-.058-4.028-.057-7.021,0-6.561.057-13.469T11.393,3.11q12.776,0,30.618-.115t33.265-.231q8.632,0,16.231.057t9.669.289"/>
                            <path id="Path_20" data-name="Path 20" d="M188.158,71.252A53.382,53.382,0,0,1,168.7,91.114a52.067,52.067,0,0,1-26.935,7.194h-5.295q-16.231,0-29.928-10.5t-17.5-25.152a46.207,46.207,0,0,1-1.719-13.042,55.13,55.13,0,0,1,3.51-19.44A37.509,37.509,0,0,1,101.6,14.539,48.705,48.705,0,0,1,119.21,3.752,63.074,63.074,0,0,1,140.85,0a65.275,65.275,0,0,1,21.927,3.684,50.6,50.6,0,0,1,18.015,10.82,40.71,40.71,0,0,1,8.978,14.909,54.415,54.415,0,0,1,3.108,18.242,60,60,0,0,1-4.72,23.6M145.224,56.4a8.266,8.266,0,0,0,2.417-6.1,8.516,8.516,0,0,0-3.223-6.906,9.49,9.49,0,0,0-6.216-2.074q-4.489,0-8.172,6.215a88.7,88.7,0,0,0,.23-9.093q0-10.471-.115-18.648t-.23-14.733a20.832,20.832,0,0,0-7.711,4.72,44.353,44.353,0,0,0-4.489,5.985A47.129,47.129,0,0,0,116.1,27.858a37.359,37.359,0,0,0,2.878,14.734,29.593,29.593,0,0,0,8.633,11.51,38.142,38.142,0,0,0,5.289,3.452,12.63,12.63,0,0,0,5.87,1.727,8.918,8.918,0,0,0,6.446-2.878"/>
                            <path id="Path_21" data-name="Path 21" d="M244.341,71.023a28.18,28.18,0,0,1-3.913,11.856,24.528,24.528,0,0,1-8.863,8.862,64.1,64.1,0,0,1-10.647,2.015,110.866,110.866,0,0,1-12.03.637q-4.376,0-13.007-.345T183.1,93.7V53.986a38.172,38.172,0,0,1,6.446-1.956,34.508,34.508,0,0,1,7.367-.805,27.792,27.792,0,0,1,9.67,1.612,16.954,16.954,0,0,1,7.482,5.525,30.429,30.429,0,0,1,4.086,6.731,17.62,17.62,0,0,1,1.323,6.85,20.231,20.231,0,0,1-1.784,7.885,16.223,16.223,0,0,1-6.389,7.194q-1.612,1.036-8.057,4.145h3.8a33.679,33.679,0,0,0,11.741-1.841q6.445-2.419,8.978-7.6a19.411,19.411,0,0,0,.459-3.333q0-5.754-6.1-12.776-3-3.223-5.87-6.562a48.8,48.8,0,0,1-8.978-19.108,90.1,90.1,0,0,1-1.842-19.914q0-4.6.115-8.921t.23-8.575h38.56Z"/>
                            <path id="Path_22" data-name="Path 22" d="M347.264,94.619,301.8,94.5,290.4,65.5,288.564,94.5q-4.145,0-6.445.115-11.512.574-12.2.576a59.985,59.985,0,0,1-13.589-1.267,24.088,24.088,0,0,1-11.223-5.641,30.238,30.238,0,0,1-6.849-9.5,25.727,25.727,0,0,1-2.532-10.881V66.122a11.085,11.085,0,0,1,.461-2.475A102.178,102.178,0,0,1,243.9,42.231a225.563,225.563,0,0,1,11.281-20.383q6.216-9.667,12.431-19.343h46.043q6.1,13.356,12.086,29.822,3.91,10.71,10.244,29.59,6.908,20.5,10.476,30.055ZM288.1,56.864q-2.762-7.483-5.41-14.964-3.223-8.981-7.943-21.18-4.26-11.163-6.215-15.193l-2.648,7.711a33.805,33.805,0,0,0-1.208,5.18,38.955,38.955,0,0,0-.4,5.87,47.886,47.886,0,0,0,.86,9.384,47.774,47.774,0,0,0,2.59,8.576,43.273,43.273,0,0,0,7.6,9.553q5.639,5.18,11.165,5.18a8.7,8.7,0,0,0,1.612-.115"/>
                        </g>
                    </svg>
                </Link>
                <div className="mt-5">
                    <NavButton link={"/about"} title={"about zoja"}></NavButton>
                </div>
                <div className="mt-2">
                    <NavButton link={"/sessions"} title={"sessions"}></NavButton>
                </div>
                <div className="mt-2">
                    <NavButton link={"/retreats"} title={"retreats"}></NavButton>
                </div>
                <div className="mt-2">
                    <NavButton link={"/contact"} title={"contact"}></NavButton>
                </div>
                <div className="mt-2">
                    <NavButton external={true} link={settings?.booking} title={"book now"}></NavButton>
                </div>
            </nav>
            <nav className={"absolute tablet:hidden w-full p-page z-10"}>
                <Link to={"/"} className={`logo w-full`}>
                    <svg className={"w-full h-auto"} xmlns="http://www.w3.org/2000/svg" width="347.264" height="98.31" viewBox="0 0 347.264 98.31">
                        <g id="Group_191" data-name="Group 191" transform="translate(0 0)">
                            <path id="Path_19" data-name="Path 19" d="M101.176,3.11a229.023,229.023,0,0,1-14.2,26.427Q79.014,42.461,71.165,55.271q-8.54,15-11.773,25.5a9.812,9.812,0,0,0-.23,1.847,8.76,8.76,0,0,0,3.108,6.576,21.642,21.642,0,0,0,6.677,4.04h8.978A105.378,105.378,0,0,1,70.4,84.026a21.437,21.437,0,0,1-4.1-11.971A28.752,28.752,0,0,1,66.416,69a15.094,15.094,0,0,1,.806-2.936,16.664,16.664,0,0,1,7.136-8.461,21.9,21.9,0,0,1,11.4-2.936,33.345,33.345,0,0,1,7.018.927,27.157,27.157,0,0,1,3.8,1.036L96,94.735,0,94.619q.229,0,10.59-13.236,12.772-16.232,22.443-29.93Q46.847,32,53.062,19.8a11.108,11.108,0,0,0,.69-3.678A10.715,10.715,0,0,0,50.99,8.984,14.534,14.534,0,0,0,44.2,4.491a15.525,15.525,0,0,0-2.992-.346,8.558,8.558,0,0,0-3.511.862q-1.9.866-3.281,1.439a15.16,15.16,0,0,1,6.676,3.913,25.062,25.062,0,0,1,4.259,6.906q.113,1.154.173,2.015t.054,1.441A23.384,23.384,0,0,1,44.31,28.49a21.329,21.329,0,0,1-4.028,6.85,18.3,18.3,0,0,1-8.748,5.525,42.068,42.068,0,0,1-12.545,1.726H14.27c-1.306-.231-2.265-.383-2.878-.462a38.457,38.457,0,0,1-.173-4.373q-.058-4.028-.057-7.021,0-6.561.057-13.469T11.393,3.11q12.776,0,30.618-.115t33.265-.231q8.632,0,16.231.057t9.669.289"/>
                            <path id="Path_20" data-name="Path 20" d="M188.158,71.252A53.382,53.382,0,0,1,168.7,91.114a52.067,52.067,0,0,1-26.935,7.194h-5.295q-16.231,0-29.928-10.5t-17.5-25.152a46.207,46.207,0,0,1-1.719-13.042,55.13,55.13,0,0,1,3.51-19.44A37.509,37.509,0,0,1,101.6,14.539,48.705,48.705,0,0,1,119.21,3.752,63.074,63.074,0,0,1,140.85,0a65.275,65.275,0,0,1,21.927,3.684,50.6,50.6,0,0,1,18.015,10.82,40.71,40.71,0,0,1,8.978,14.909,54.415,54.415,0,0,1,3.108,18.242,60,60,0,0,1-4.72,23.6M145.224,56.4a8.266,8.266,0,0,0,2.417-6.1,8.516,8.516,0,0,0-3.223-6.906,9.49,9.49,0,0,0-6.216-2.074q-4.489,0-8.172,6.215a88.7,88.7,0,0,0,.23-9.093q0-10.471-.115-18.648t-.23-14.733a20.832,20.832,0,0,0-7.711,4.72,44.353,44.353,0,0,0-4.489,5.985A47.129,47.129,0,0,0,116.1,27.858a37.359,37.359,0,0,0,2.878,14.734,29.593,29.593,0,0,0,8.633,11.51,38.142,38.142,0,0,0,5.289,3.452,12.63,12.63,0,0,0,5.87,1.727,8.918,8.918,0,0,0,6.446-2.878"/>
                            <path id="Path_21" data-name="Path 21" d="M244.341,71.023a28.18,28.18,0,0,1-3.913,11.856,24.528,24.528,0,0,1-8.863,8.862,64.1,64.1,0,0,1-10.647,2.015,110.866,110.866,0,0,1-12.03.637q-4.376,0-13.007-.345T183.1,93.7V53.986a38.172,38.172,0,0,1,6.446-1.956,34.508,34.508,0,0,1,7.367-.805,27.792,27.792,0,0,1,9.67,1.612,16.954,16.954,0,0,1,7.482,5.525,30.429,30.429,0,0,1,4.086,6.731,17.62,17.62,0,0,1,1.323,6.85,20.231,20.231,0,0,1-1.784,7.885,16.223,16.223,0,0,1-6.389,7.194q-1.612,1.036-8.057,4.145h3.8a33.679,33.679,0,0,0,11.741-1.841q6.445-2.419,8.978-7.6a19.411,19.411,0,0,0,.459-3.333q0-5.754-6.1-12.776-3-3.223-5.87-6.562a48.8,48.8,0,0,1-8.978-19.108,90.1,90.1,0,0,1-1.842-19.914q0-4.6.115-8.921t.23-8.575h38.56Z"/>
                            <path id="Path_22" data-name="Path 22" d="M347.264,94.619,301.8,94.5,290.4,65.5,288.564,94.5q-4.145,0-6.445.115-11.512.574-12.2.576a59.985,59.985,0,0,1-13.589-1.267,24.088,24.088,0,0,1-11.223-5.641,30.238,30.238,0,0,1-6.849-9.5,25.727,25.727,0,0,1-2.532-10.881V66.122a11.085,11.085,0,0,1,.461-2.475A102.178,102.178,0,0,1,243.9,42.231a225.563,225.563,0,0,1,11.281-20.383q6.216-9.667,12.431-19.343h46.043q6.1,13.356,12.086,29.822,3.91,10.71,10.244,29.59,6.908,20.5,10.476,30.055ZM288.1,56.864q-2.762-7.483-5.41-14.964-3.223-8.981-7.943-21.18-4.26-11.163-6.215-15.193l-2.648,7.711a33.805,33.805,0,0,0-1.208,5.18,38.955,38.955,0,0,0-.4,5.87,47.886,47.886,0,0,0,.86,9.384,47.774,47.774,0,0,0,2.59,8.576,43.273,43.273,0,0,0,7.6,9.553q5.639,5.18,11.165,5.18a8.7,8.7,0,0,0,1.612-.115"/>
                        </g>
                    </svg>
                </Link>
            </nav>
            <div className={`fixed bottom-[1rem] right-[1rem] tablet:hidden z-50`}>
                <div className={nav ? "burger close" : "burger"} onClick={() => {
                    changeNav();
                }}>
                    {!navBtn && <span className="">(menu)</span>}
                    {navBtn && <span className="">(close)</span>}
                </div>
            </div>
            <Menu className={`menu fade-in z-30 fixed bottom-[0rem] w-screen`} nav={nav} ref={menuRef}>
                    <nav className={"p-page flex flex-col justify-end h-[100vh]"}>
                        <Link to={"/"} onClick={() => {changeNav();}} className={`logo h-[calc(100vh-300px)] w-fit`}>
                            <svg className={"h-[calc(100vh-300px)] w-auto"} xmlns="http://www.w3.org/2000/svg" width="149" height="526.368" viewBox="0 0 149 526.368">
                                <g id="Group_186" data-name="Group 186" transform="translate(0 0.001)">
                                    <g id="Group_88" data-name="Group 88" transform="translate(4.188 373.005)">
                                        <path id="Path_11" data-name="Path 11" d="M.167,0Q16.96,7.348,40.223,21.521q19.59,12.071,39.006,23.968,22.744,12.944,38.657,17.844a14.93,14.93,0,0,0,2.8.349,13.281,13.281,0,0,0,9.968-4.711,32.806,32.806,0,0,0,6.124-10.12V35.243q-10.466,9.161-13.957,11.4-9.247,6.219-18.145,6.219a43.48,43.48,0,0,1-4.623-.175,22.918,22.918,0,0,1-4.45-1.222A25.263,25.263,0,0,1,82.778,40.652a33.2,33.2,0,0,1-4.45-17.272,50.518,50.518,0,0,1,1.4-10.644A41.023,41.023,0,0,1,81.3,6.979l57.75.873-.176,145.51q0-.348-20.063-16.052-24.6-19.366-45.365-34.022Q43.963,82.351,25.464,72.929a16.832,16.832,0,0,0-5.582-1.046A16.241,16.241,0,0,0,9.063,76.07a22.024,22.024,0,0,0-6.8,10.295A23.458,23.458,0,0,0,1.737,90.9a12.959,12.959,0,0,0,1.307,5.321q1.312,2.881,2.182,4.974a22.971,22.971,0,0,1,5.932-10.119A38,38,0,0,1,21.625,84.62q1.747-.172,3.055-.263t2.182-.087A35.458,35.458,0,0,1,38.636,86.19,32.315,32.315,0,0,1,49.019,92.3a27.735,27.735,0,0,1,8.374,13.26,63.733,63.733,0,0,1,2.617,19.018v7.152c-.351,1.979-.581,3.433-.7,4.362a58.223,58.223,0,0,1-6.628.263q-6.1.09-10.642.087-9.945,0-20.415-.087T.167,136.088q0-19.366-.175-46.409T-.357,39.257q0-13.085.087-24.6T.167,0" transform="translate(0.357)"/>
                                    </g>
                                    <g id="Group_89" data-name="Group 89" transform="translate(0 234.009)">
                                        <path id="Path_12" data-name="Path 12" d="M34.386,2.278a80.927,80.927,0,0,1,30.1,29.486,78.929,78.929,0,0,1,10.9,40.827v8.025q0,24.6-15.912,45.363T21.35,152.5a70.052,70.052,0,0,1-19.762,2.617,83.561,83.561,0,0,1-29.467-5.321,56.853,56.853,0,0,1-23.7-16.314,73.814,73.814,0,0,1-16.351-26.694,95.607,95.607,0,0,1-5.685-32.8A98.969,98.969,0,0,1-68.029,40.75a76.694,76.694,0,0,1,16.4-27.306A61.706,61.706,0,0,1-29.036-.165,82.5,82.5,0,0,1-1.38-4.876,90.928,90.928,0,0,1,34.386,2.278M11.879,67.356a12.53,12.53,0,0,0-9.247-3.663A12.91,12.91,0,0,0-7.836,68.578,14.386,14.386,0,0,0-10.978,78q0,6.8,9.42,12.387-1.741-.348-13.782-.349-15.873,0-28.265.175t-22.331.349a31.588,31.588,0,0,0,7.154,11.688,67.321,67.321,0,0,0,9.071,6.8A71.4,71.4,0,0,0-31.389,111.5a56.627,56.627,0,0,0,22.332-4.362A44.862,44.862,0,0,0,8.39,94.05a57.809,57.809,0,0,0,5.233-8.025,19.132,19.132,0,0,0,2.617-8.9,13.52,13.52,0,0,0-4.362-9.771" transform="translate(73.613 4.876)"/>
                                    </g>
                                    <g id="Group_90" data-name="Group 90" transform="translate(3.839 156.004)">
                                        <path id="Path_13" data-name="Path 13" d="M33.052,0A42.712,42.712,0,0,1,51.023,5.932,37.176,37.176,0,0,1,64.456,19.366,97.023,97.023,0,0,1,67.511,35.5a168.068,168.068,0,0,1,.959,18.233q0,6.633-.522,19.715T67.423,92.82H7.228a57.841,57.841,0,0,1-2.965-9.771A52.287,52.287,0,0,1,3.043,71.883,42.129,42.129,0,0,1,5.485,57.226,25.7,25.7,0,0,1,13.86,45.885a46.1,46.1,0,0,1,10.207-6.194,26.691,26.691,0,0,1,10.381-2.006A30.684,30.684,0,0,1,46.4,40.39a24.594,24.594,0,0,1,10.9,9.684q1.571,2.445,6.282,12.212V56.529a51.066,51.066,0,0,0-2.79-17.8q-3.666-9.771-11.515-13.609a29.489,29.489,0,0,0-5.062-.7q-8.721,0-19.364,9.247-4.885,4.54-9.946,8.9A73.952,73.952,0,0,1-14.055,56.18q-12.04,2.793-30.184,2.792-6.976,0-13.522-.175t-13-.349V0H33.052Z" transform="translate(70.759)"/>
                                    </g>
                                    <g id="Group_91" data-name="Group 91" transform="translate(3.795 -0.001)">
                                        <path id="Path_14" data-name="Path 14" d="M44.454,0l-.175,68.916L.313,86.188,44.279,88.98q0,6.281.175,9.77.871,17.449.873,18.494a90.945,90.945,0,0,1-1.92,20.588q-2.441,10.469-8.55,17.011a45.821,45.821,0,0,1-14.4,10.381,38.987,38.987,0,0,1-16.493,3.838h-2.7a16.808,16.808,0,0,1-3.751-.7,154.9,154.9,0,0,1-32.461-11.688,341.842,341.842,0,0,1-30.893-17.1q-14.654-9.421-29.319-18.842V50.945q20.244-9.247,45.2-18.32Q-33.731,26.7-5.114,17.1,25.957,6.629,40.441,1.221ZM-12.772,89.679q-11.342,4.186-22.682,8.2-13.611,4.885-32.1,12.039-16.921,6.457-23.029,9.42l11.688,4.014a51.241,51.241,0,0,0,7.852,1.831,59.154,59.154,0,0,0,8.9.61,72.59,72.59,0,0,0,14.219-1.307,72.354,72.354,0,0,0,13-3.926,65.586,65.586,0,0,0,14.48-11.515Q-12.6,100.5-12.6,92.121a13.206,13.206,0,0,0-.175-2.443" transform="translate(95.167)"/>
                                    </g>
                                </g>
                            </svg>
                        </Link>
                        <div className="h-[230px]">
                            <div className="mt-5">
                                <div onClick={() => {changeNav();}}>
                                    <NavButton link={"/about"} title={"about zoja"}></NavButton>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div onClick={() => {changeNav();}}>
                                    <NavButton link={"/sessions"} title={"sessions"}></NavButton>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div onClick={() => {changeNav();}}>
                                    <NavButton link={"/retreats"} title={"retreats"}></NavButton>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div onClick={() => {changeNav();}}>
                                    <NavButton link={"/contact"} title={"contact"}></NavButton>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div onClick={() => {changeNav();}}>
                                    <NavButton external={true} link={settings?.booking} title={"book now"}></NavButton>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div onClick={() => {changeNav();}}>
                                    <NavButton link={"/imprint"} title={"imprint"}></NavButton>
                                </div>
                            </div>
                        </div>
                    </nav>
            </Menu>
            <div className="hidden tablet:flex items-center bottomnav fixed bottom-0 left-0 h-bottomnav bg-white w-screen z-30">
                <div className="flex items-end">
                    <div className="w-leftpage px-page">
                        <p className={"text-p"}>{settings?.intro?.introslidertext}</p>
                    </div>
                    <Marquee className={""} gradient={false}>
                        {settings?.intro?.introslidertag?.map((tag, index) => {
                            return (
                                <div key={index} className="px-page flex items-end">
                                    <p className={"text-p"}>{tag}</p>
                                </div>
                            )
                        })}
                        {settings?.intro?.introslidertag?.map((tag, index) => {
                            return (
                                <div key={index} className="px-page flex items-end">
                                    <p className={"text-p"}>{tag}</p>
                                </div>
                            )
                        })}
                    </Marquee>
                </div>
            </div>
        </div>
    )
}
export default Navigation;
