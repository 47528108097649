import React from "react";
import {Link} from "gatsby";

const NavButton = ({link, title, width = '100%', padding = 0, external = false}) => {


    return (
        <Link to={link} target={external ? '_blank' : '_self'} style={{width: width, paddingLeft: padding, paddingRight: padding}} className={"mono text-nav border-2 border-black text-black block text-center whitespace-nowrap py-0.5 hover:bg-black hover:text-white duration-300 ease-in-out transition-all"}>
            {title}
        </Link>
    )
}


export default NavButton
